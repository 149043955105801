import styled from "styled-components";
import settingsStyled from "../../../styled/settings.styled";
//const imagePath = require("../../../../public/images/home/solutionCategoriesFrame.png");
const SolutionCategoriesStyled = styled.section`
  position: relative;
  overflow: hidden;
  z-index: 1;
  width: 100%;
  //height: 125.35vh;
  height: 1012px;
  flex-shrink: 0;
  //border-radius: 12px;
  background-size: cover;
  background-position: center center;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  flex-direction: column;

  @media ${settingsStyled.m767} {
    padding: 3.911vw;
    height: auto;
    margin-top: 100px;
  }

  .solutionCatgories-container {
    background-color: transparent;
    display: flex;
    flex-direction: column;
    height: 80%;
    width: 100%;
    justify-content: center;
    align-items: center;
    &-title {
      flex: 1;
      margin-top: 110px;
      @media ${settingsStyled.m767} {
        margin-top: 30px;
      }
      p {
        color: #fff;
        font-family: Inter;
        font-display: swap;
        font-size: 40px;
        font-style: normal;
        font-weight: 400;
        line-height: 135%; /* 54px */
        text-align: center;

        @media ${settingsStyled.m767} {
          font-size: 5.215vw;
          text-align: center;
        }
      }
    }
    &-subTitle {
      flex: 1;
      display: flex;
      justify-content: center;
      margin-top: 30px;
      margin-bottom: 62px;
      @media ${settingsStyled.m767} {
        margin-top: 10px;
        margin-bottom: 24px;
      }

      p {
        width: 80%;
        color: #fff;
        text-align: center;
        font-family: Inter;
        font-display: swap;
        font-size: 26px;
        font-style: normal;
        font-weight: 200;
        line-height: 40px; /* 142.857% */

        @media ${settingsStyled.m767} {
          font-size: 3.077vw;
          line-height: 5.215vw;
        }
      }
    }
    &-description {
      flex: 1;
      gap: 3.278vw;
      display: flex;
      align-items: center;
      letter-spacing: 0.1em;
      margin-bottom: 75px;

      @media ${settingsStyled.m767} {
        margin-bottom: 25px;
        letter-spacing: 0;
        gap: 15px;
      }

      &::before,
      &::after {
        content: "";
        width: 2.583vw;
        height: 1px;
        background-color: #fff;
        flex-grow: 1;

        @media ${settingsStyled.m767} {
          width: 20px;
        }
      }

      &::before {
        margin-right: var(--text-divider-gap);
      }

      &::after {
        margin-left: var(--text-divider-gap);
      }

      p {
        color: #fff;
        text-align: center;
        font-family: Inter;
        font-display: swap;
        font-size: 26px;
        font-style: normal;
        font-weight: 600;
        line-height: 35px; /* 134.615% */

        @media ${settingsStyled.m767} {
          line-height: normal;
          font-size: 2.821vw;
        }
      }
    }
    &-solutionButtons {
      flex: 7;
      display: flex;
      width: 1038px;
      // max-height:25.623vw;
      margin-bottom: 109px;
      @media ${settingsStyled.m767} {
        width: 100%;
        margin-bottom: 20px;
      }
      @media ${settingsStyled.m1200} {
        width: 86.5vw;
      }
    }
  }
`;

const CategoriesStyled = styled.div`
  background: transparent;
  width: 240px;
  height: 217px;
  flex-shrink: 0;
  border-radius: 20px;
  border: 1px solid #fff;
  box-shadow: 0px 0px 6.6px 0px rgba(95, 94, 164, 0.25);
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .ant-col .gutter-column {
    margin-left: 0;
  }
  @media ${settingsStyled.m1200} {
    width: 20vw;
    height: 18.083vw;
  }
  @media ${settingsStyled.m479} {
    width: 27.129vw;
    height: 28.042vw;
  }
  position: relative;
  &:hover {
    background: rgba(243, 246, 255, 0.25);
    box-shadow: 0px 0px 6.6px 4px rgba(83, 131, 255, 0.25);

    .solutionCategory-button {
      opacity: 1;
    }
  }

  .solutionCategory-icons {
    flex: 4;
    & > div > svg {
      fill: #365097;
      width: 213.656px;
      height: 147.204px;
      @media ${settingsStyled.m1200} {
        width: 17.805vw;
        height: 12.267vw;
      }
      @media ${settingsStyled.m479} {
        width: 94px;
        height: 65px;
      }
    }
  }
  .solutionCategory-title {
    flex: 1;
    width: 100%;
    p {
      padding: 5px;
      color: #eef2fb;
      text-align: center;
      font-family: Inter;
      font-display: swap;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 25px; /* 125% */
      @media ${settingsStyled.m1200} {
        font-size: 1.667vw;
        line-height: 2.083vw;
      }
      @media ${settingsStyled.m479} {
        font-size: 2.667vw;
        line-height: 2.083vw;
      }
    }
  }

  .solutionCategory-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #18ad00;
    color: white;
    button {
      flex-grow: 0;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 180px;
      height: 60px;
      @media ${settingsStyled.m959} {
        width: 18.77vw;
        height: 6.257vw;
      }
      @media ${settingsStyled.m479} {
        width: 90px;
        height: 35px;
      }
      border-color: transparent;
      span {
        color: var(--bw-white, #fff);
        font-size: 20px;
        font-style: normal;
        font-weight: 200;
        line-height: 12.705px;
        @media ${settingsStyled.m479} {
          line-height: normal;
          font-size: 10px;
        }
      }
    }

    border: none;
    border-radius: 5px;
    cursor: pointer;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    box-shadow: 0px 2px 25.9px 0px rgba(255, 255, 255, 0.6);
    &:hover {
      background: darkgreen;
    }
  }
`;

const ComplateLineSolutionsStyled = styled.section`
  width: 100%;
  margin-top: 153px;
  margin-bottom: 38px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 22px;

  @media ${settingsStyled.m1023} {
    margin-top: 113px;
  }

  @media ${settingsStyled.m767} {
    margin-top: 83px;
  }

  @media ${settingsStyled.m479} {
    margin-top: 60px;
    margin-bottom: 60px;
  }

  .complateSolutions {
    &-content {
      width: 70%;
      margin: auto;
      gap: 22px;
      text-align: center;

      @media ${settingsStyled.m767} {
        width: 90%;
      }
    }

    &-tabs {
      margin-top: 32px;
      width: 100%;
      position: relative;

      @media ${settingsStyled.m479} {
        margin-top: 16px;

        .ant-tabs-content-holder {
          margin-top: 0;
          margin-bottom: 0;
        }
      }

      &-image {
        width: 100%;
        max-height: 613px;

        @media ${settingsStyled.m1200} {
          max-height: 513px;
        }

        @media ${settingsStyled.m959} {
          max-height: 447px;
        }

        @media ${settingsStyled.m959} {
          max-height: 347px;
        }

        @media ${settingsStyled.m479} {
          max-height: 61.026vw;
          margin-top: 16px;
        }

        .button-container {
          position: absolute;
          bottom: 83px;
          left: 50%;
          transform: translateX(-50%);

          @media ${settingsStyled.m767} {
            bottom: 5.385vw;
          }

          &-button {
            button {
              background-color: #1c3b84;
              border-radius: 20px;
              display: flex;
              width: 245px;
              height: 80px;
              padding: 10px 30px;
              justify-content: center;
              align-items: center;
              gap: 10px;
              border-color: transparent;

              @media ${settingsStyled.m767} {
                width: 26.154vw;
                height: 10.256vw;
                border-radius: 2.051vw;
              }

              &:hover {
                background-color: #365097;
              }

              span {
                color: #fff;
                font-size: 24px;
                font-style: normal;
                font-weight: 500;
                line-height: 24.726px;
                color: white;

                @media ${settingsStyled.m479} {
                  font-size: 2.564vw !important;
                }
              }
            }
          }
        }
        img {
          width: 100%;
          max-height: 613px;

          @media ${settingsStyled.m1200} {
            max-height: 513px;
          }

          @media ${settingsStyled.m959} {
            max-height: 447px;
          }

          @media ${settingsStyled.m959} {
            max-height: 347px;
          }

          @media ${settingsStyled.m767} {
            max-height: 327px;
          }

          @media ${settingsStyled.m479} {
            max-height: 61.026vw;
          }
        }
      }
    }
  }

  .ant-tabs-nav-list {
    padding-left: 32px;
  }
`;

const BackgroundOpacityStyled = styled.div`
  position: relative;
  overflow: hidden;
  z-index: 1;
  width: 100%;
  //height: 125.35vh;
  height: ${(props) => props.height || "100px"};
  flex-shrink: 0;
  //border-radius: 12px;
  margin-top: 87px;
  background-image: linear-gradient(
      rgba(28, 59, 132, 0.7),
      rgba(17, 94, 221, 0.75)
    ),
    url(${(props) => props.imageUrl});

  background-size: cover;
  background-position: center center;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  flex-direction: column;
`;

const CategoriesPdfsStyled = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
  height: 80%;
  justify-content: center;
  align-items: center;
  .categoriesPdf {
    &-title {
      flex: 2;
      width: 517px;
      p {
        color: var(--bw-white, #fff);
        text-align: center;
        font-family: Inter;
        font-display: swap;
        font-size: 36px;
        font-style: normal;
        font-weight: 600;
        line-height: 56px;
      }
    }
    &-pdfs {
      flex: 10;
      width: 80%;
      height: 80%;

      margin-top: 72px;
    }
  }
`;

const CategoriesPdfsCardStyled = styled.div`
  width: 100%;
  height: 100%;
  padding: 2rem;
  //backgroundColor: hsl(280deg; 40%; calc(100% - var(--abs-offset) * 50%));
  //border-radius: 1rem;
  color: gray;
  text-align: justify;
  transition: all 0.3s ease-out;
  border-radius: 19.562px;
  background: rgba(255, 255, 255, 0.6);
  box-shadow: 0px 2.675px 7.003px 1.338px rgba(103, 103, 103, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .card {
    &-pdfIcon {
      width: 37.86px;
      height: 37.86px;
      border-radius: 50%;
      background-color: lightgrey;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #1c3b84;
      position: absolute;
      right: 35px;
      bottom: 75px;
    }
    &-title {
      text-align: center;
      color: #101010;
      text-align: center;
      font-family: Inter;
      font-display: swap;
      font-size: 20.906px;
      font-style: normal;
      font-weight: 400;
      line-height: 37.455px; /* 179.159% */
      text-decoration-line: underline;
      margin-top: 10px;
    }
  }
`;

export {
  CategoriesPdfsCardStyled,
  CategoriesPdfsStyled,
  SolutionCategoriesStyled,
  CategoriesStyled,
  ComplateLineSolutionsStyled,
  BackgroundOpacityStyled,
};
